import React, { Component } from "react";
import { Header } from "semantic-ui-react";


export default class Placeholder extends Component {
 
  render() {
    return (
      <div>
        <Header as="h1">The report site for FSR Lab is currently down for maintenanse.<br/> Please wait or contact the Lab for udpate</Header>
    </div>
    );
  }
}
