import React, { Component } from "react";
import {
  Header,
  Table,
  Button,
  Icon,
  Dropdown,
  Form,
  Pagination,
  Grid,
  Segment,
  Dimmer,
  Loader,
  Input,
  Responsive
} from "semantic-ui-react";

import "./External.css";
import _ from "underscore";
import moment from "moment";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
// import Errorpop from "../error/Errorpop";
// import ExternalLogin from "./ExternalLogin";
var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require('axios');

export default class External extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selMinRcvDate: null,
      selMaxRcvDate: null,
      testOptions: [],
      pageOptions:[{key:20,value:20,text:'20'},{key:50,value:50,text:'50'},{key:100,value:100,text:'100'}],
      dateOptions:[{key:7,value:7,text:'One Week'},{key:30,value:30,text:'One Month'},{key:365,value:365,text:'One Year'},{key:0,value:0,text:'Customize'}],
      statusOptions:[{key:0,value:0,text:'All'},{key:1,value:1,text:'Received'},{key:2,value:2,text:'Reported'}],
      resOptions:[{key:0,value:0,text:'All'},{key:1,value:1,text:'Positive'},{key:2,value:2,text:'Negative'},{key:3,value:3,text:'Presumptive Positive'}],
      pageSize: 20,
      sampleData:[],
      activepage:1,
      totalpage:1,
      sort_col:'labID',
      sort_dir:'asc',
      selTest:'',
      dateRange:30,
      analysisID:'',
      statusfilter:0,
      resfilter:0,
      disableDateRange:true,
      search_cond:'',
      totalCount:0,
      loading:true,
      searchKey:''
      };
    this.errorpop = React.createRef();
    }



 saveOption = (type, data) => {
  //console.log(data);
  if (type === "daterange") 
  {
  this.setState({ dateRange: data.value,disableDateRange:data.value!==0 });
  if (data.value===7)  this.setState( {selMinRcvDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
  selMaxRcvDate: moment().format('YYYY-MM-DD')});
  else if (data.value===30)  this.setState( {selMinRcvDate: moment().subtract(1,'months').format('YYYY-MM-DD'),
  selMaxRcvDate: moment().format('YYYY-MM-DD')});
  else if (data.value===365)  this.setState( {selMinRcvDate: moment().subtract(1,'y').format('YYYY-MM-DD'),
  selMaxRcvDate: moment().format('YYYY-MM-DD')});
  }

  // else if (type === "customer") this.setState({ selCust: data.value });
  else if (type === "test") this.setState({ analysisID: data.value });
  else if (type === "result") this.setState({resfilter:data.value});
  else if (type === "status") this.setState({statusfilter:data.value});
  else if (type === "search") this.setState({searchKey:data.value});
  else if (type === "rcvDt")  if (data.length>1) this.setState({ selMinRcvDate: moment(data[0]).format('YYYY-MM-DD') ,selMaxRcvDate: moment(data[1]).format('YYYY-MM-DD')  });
  // console.log(this.state);
};

getData=(email)=>{
  this.setState({loading:true});
  var searchcond="";
  console.log(this.state.activepage);
  if (this.state.search_cond==='') searchcond=" DATE_FORMAT(`sample_date`,'%Y-%m-%d')>='"+this.state.selMinRcvDate+"' and DATE_FORMAT(`sample_date`,'%Y-%m-%d')<='"+this.state.selMaxRcvDate+"'";
 else searchcond=this.state.search_cond
  axios.post('/api/getSamplesExt',{email:email,sort_col:this.state.sort_col,
    sort_dir:this.state.sort_dir, clause: searchcond,
  start:this.state.pageSize*(this.state.activepage-1),
  size:this.state.pageSize}).then(res=>
  {
    console.log(res.data);
    this.setState({totalpage:Math.ceil(res.data.cnt/this.state.pageSize),
      sampleData:res.data.data,
      totalCount:res.data.cnt,
    loading:false});
  })
}

handlePaginationChange = (e, data) => 
{  console.log(data);
    this.setState({ activepage : data.activePage},
      ()=>this.getData(this.state.username)) ;

}

changeSize = (size) =>
{
  this.setState({pageSize:size, activepage:1},
  ()=>this.getData(this.state.username));
}

 componentDidMount() {
  this.setState( {selMinRcvDate: moment().subtract(1,'months').format('YYYY-MM-DD'),
  selMaxRcvDate: moment().format('YYYY-MM-DD')});
  axios.get('/api/getextuser').then(res=>{
    console.log(res.data);
    this.setState({loggedin:res.data.loggedin});
     if (res.data.loggedin) 
       { var username=res.data.name;
         this.setState({username:username,custname:res.data.custname});
       axios.get('/api/getTest').then(res=>{
         console.log(res.data);
        this.setState({testOptions:_.filter(res.data,function(item){return item.text.toLowerCase().indexOf('confirm')===-1})}
        , () => this.getData(username));
       });

       }
    console.log(this.state.loggedin);
  })
  
 }

 
getReport = async(filename) => {
  const response = await fetch("/api/getPDF?filename="+filename, {
      method: "GET",
      headers: {
        Accept: "application/blob",
        "Content-Type": "application/json"
      }
  })
  const body = await response.blob();
  if (response.status !== 200) throw Error(body.message);
  console.log(body);
  return body;
}

downloadReport = (filename) =>{
  this.getReport(filename).then(
      res=>{

        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
          window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
          const fileURL = URL.createObjectURL(res);
          var anchor = document.createElement('a');
          document.body.appendChild(anchor);
          anchor.setAttribute("type", "hidden");
          anchor.download = filename;
          anchor.href=fileURL;
          anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
           anchor.click();
      }
      }
  )
}

sortClick = (col_name) => {
  
  var newsortdir='asc';
  var newsortcol=this.state.sort_col;
  if (col_name!==this.state.sort_col) 
{
newsortdir='asc';
newsortcol=col_name;
}
else 
newsortdir=this.state.sort_dir==='asc'?'desc':'asc';
this.setState({sort_col:newsortcol,sort_dir:newsortdir, activepage:1
},
  ()=>this.getData(this.state.username))
}

applyFilter = () => {
 
  console.log(this.state.analysisID)
  var search_cond=''
  if (this.state.analysisID!=='') search_cond=search_cond+"test='"+this.state.analysisID+"'"
  if (this.state.dateRange!=='') search_cond=search_cond+(search_cond!==''?' AND':'')+" DATE_FORMAT(`sample_date`,'%Y-%m-%d')>='"+this.state.selMinRcvDate+"' and DATE_FORMAT(`sample_date`,'%Y-%m-%d')<='"+this.state.selMaxRcvDate+"'";
  if (this.state.statusfilter!==0) search_cond=search_cond+(search_cond!==''?' AND':'')+" upper(status)=upper('"+_.where(this.state.statusOptions,{key:this.state.statusfilter})[0].text+"')"
  if (this.state.resfilter!==0) 
  { 
    var resultstring=_.where(this.state.resOptions,{key:this.state.resfilter})[0].text==='Presumptive Positive'?'PP':_.where(this.state.resOptions,{key:this.state.resfilter})[0].text;
    search_cond=search_cond+(search_cond!==''?' AND':'')+" upper(tst_result)=upper('"+resultstring+"')"
}
if (this.state.searchKey!=='') 
{
 var searcharr=this.state.searchKey.split(' ').map((data,index)=>{
    return " upper(`desc`) like '%"+data.toUpperCase()+"%' "
  });
  search_cond=search_cond+(search_cond!==''?' AND':'')+" "+searcharr.join("AND")+" ";
}
  console.log(search_cond);
  this.setState({search_cond:search_cond,activepage:1},
    ()=>this.getData(this.state.username))
}

removeFilter = () => {
this.setState({analysisID:'', search_cond:'',activepage:1,dateRange:30,selMinRcvDate: moment().subtract(1,'months').format('YYYY-MM-DD'),
selMaxRcvDate: moment().format('YYYY-MM-DD'),resfilter:0,statusfilter:0,searchKey:''},
()=>this.getData(this.state.username))
}


  render() {
    var newid=false;
    var labID=0;

   var tablebody=this.state.sampleData.map((data,index)=>{
   var filename=data.report_id+"-R"+data.CURR_REV.toString()+".pdf";

    if (data.labID!==labID) 
    {labID=data.labID; newid=true}
    else newid=false;
    var maxrow=1;
    while (typeof(this.state.sampleData[index+maxrow])!='undefined' && data.labID===this.state.sampleData[index+maxrow].labID) maxrow++;
    var downloadcell=<Table.Cell></Table.Cell>
    if (data.CURR_REV!=='') 
    downloadcell=<Table.Cell className='download' onClick={()=>this.downloadReport(filename)}><a target="_blank">Download</a></Table.Cell>
    if (newid)  
   return <Table.Row key={'row'+index}>
      <Table.Cell rowSpan={maxrow}>{data.labID}</Table.Cell>
      <Table.Cell rowSpan={maxrow}>{data.seqid}</Table.Cell>
      <Table.Cell rowSpan={maxrow}>{moment(data.sample_date).format('YY-MM-DD HH:mm')}</Table.Cell>
      <Table.Cell rowSpan={maxrow}>{moment(data.receive_date).format('YY-MM-DD')}</Table.Cell>
        <Table.Cell rowSpan={maxrow}>{data.desc}</Table.Cell>
        <Table.Cell >{data.analysisNM}</Table.Cell>
        <Table.Cell>{data.status}</Table.Cell>
        <Table.Cell>{data.tst_result==='PP'?'Presumptive Positive':data.tst_result}</Table.Cell>
        <Table.Cell>{data.confirm_tst_result==='null'?'':data.confirm_tst_result}</Table.Cell>
        {downloadcell}
    </Table.Row>
    else 
    return <Table.Row key={'row'+index}>
          <Table.Cell >{data.analysisNM}</Table.Cell>
        <Table.Cell>{data.status}</Table.Cell>
        <Table.Cell>{data.tst_result==='PP'?'Presumptive Positive':data.tst_result}</Table.Cell>
        <Table.Cell>{data.confirm_tst_result==='null'?'':data.confirm_tst_result}</Table.Cell>
        {downloadcell}
    </Table.Row>
    })


    const { sort_col, sort_dir } = this.state;
    
    var unsorticon=<Icon name='sort' />;
    var ascicon=<Icon name='sort up'/>
    var dscicon=<Icon name='sort down'/>
    var receiveicon=unsorticon;
    if (sort_col === "receive_date" && sort_dir==='asc' ) receiveicon=ascicon
    else if (sort_col === "receive_date" && sort_dir==='desc' ) receiveicon=dscicon;
    var sampleIcon=unsorticon;
    if (sort_col === "sample_date" && sort_dir==='asc' ) sampleIcon=ascicon
    else if (sort_col === "sample_date" && sort_dir==='desc' ) sampleIcon=dscicon;
    var descIcon=unsorticon;
    if (sort_col === "desc" && sort_dir==='asc' ) descIcon=ascicon
    else if (sort_col === "desc" && sort_dir==='desc' ) descIcon=dscicon;
    var testIcon=unsorticon;
    if (sort_col === "test" && sort_dir==='asc' ) testIcon=ascicon
    else if (sort_col === "test" && sort_dir==='desc' ) testIcon=dscicon;
    var statusIcon=unsorticon;
    if (sort_col === "status" && sort_dir==='asc' ) statusIcon=ascicon
    else if (sort_col === "status" && sort_dir==='desc' ) statusIcon=dscicon;
    var resultIcon=unsorticon;
    if (sort_col === "result" && sort_dir==='asc' ) resultIcon=ascicon
    else if (sort_col === "result" && sort_dir==='desc' ) resultIcon=dscicon;
    return (
          <div> <link
          rel="stylesheet"
          href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.0/semantic.min.css"
        />     
             <Form>
          <Form.Group>
          <Form.Field
            control={Dropdown}
            label="Test"
            placeholder="Select Test"
            fluid
            selection
            search
            clearable
            options={this.state.testOptions}
            value={this.state.analysisID}
            width={3}
            onChange={(e, data) => this.saveOption("test",data)}
          />
            <Form.Field
            control={Dropdown}
            label="Sample Date Range"
            fluid
            selection
            search
            options={this.state.dateOptions}
            value={this.state.dateRange}
            width={3}
            onChange={(e, data) => this.saveOption("daterange",data)}
            />
          <Form.Field
              control={Flatpickr}
              width={4}
              label='Customized Range'
              value={[this.state.selMinRcvDate, this.state.selMaxRcvDate]}
              options={{
               mode:"range",
               defaultDate: [this.state.selMinRcvDate, this.state.selMaxRcvDate]
               }}
              onChange={(selectedDates) => this.saveOption("rcvDt", selectedDates)}
              disabled={this.state.disableDateRange}
            />
            <Form.Field
            control={Dropdown}
            label="Filter by Status"
            placeholder="Status"
            fluid
            selection
            search
            options={this.state.statusOptions}
            value={this.state.statusfilter}
            width={2}
            onChange={(e, data) => this.saveOption("status",data)}
            />
            <Form.Field
            control={Dropdown}
            label="Filter by Result"
            fluid
            selection
            search
            options={this.state.resOptions}
            value={this.state.resfilter}
            width={3}
            onChange={(e, data) => this.saveOption("result",data)}
            />
            </Form.Group>
            <Form.Group>
            <Form.Field
            control={Input}
            label="Search by Keyword in Description"
            placeholder="Type in Keyword(s), the result will show sample where all keywords can be found in description"
            fluid
            value={this.state.searchKey}
            width={14}
            onChange={(e, data) => this.saveOption("search",data)}
          />
            <Button
              className="search-button"
              primary
              onClick={this.applyFilter}
            >
              Search
            </Button>
            <Button
              className="search-button"
              secondary
              onClick={this.removeFilter}
            >
              Reset
            </Button>
            </Form.Group>
            </Form>
            <Segment className='resultContainer' basic>
      <Dimmer active={this.state.loading} inverted>
            <Loader size='medium'>Loading</Loader>
      </Dimmer>
      <Table unstackable sortable celled fixed size='small' className='resultView'>
          <Table.Header>
            <Table.Row >
            <Table.HeaderCell width={2} >Lab ID </Table.HeaderCell>
                <Table.HeaderCell width={1}>Ref No.</Table.HeaderCell>
                <Table.HeaderCell width={2} onClick={()=>this.sortClick('sample_date')}
                >Sample Date {sampleIcon} </Table.HeaderCell>
                <Table.HeaderCell width={1} onClick={()=>this.sortClick('receive_date')}>Received {receiveicon} </Table.HeaderCell>
                <Table.HeaderCell width={5} onClick={()=>this.sortClick('desc')}>Description {descIcon}</Table.HeaderCell>
                <Table.HeaderCell width={1}  onClick={()=>this.sortClick('test')}>Test {testIcon}</Table.HeaderCell>
                <Table.HeaderCell width={1} onClick={()=>this.sortClick('status')}>Status {statusIcon}</Table.HeaderCell>
                <Table.HeaderCell width={1} onClick={()=>this.sortClick('result')}>Result {resultIcon}</Table.HeaderCell>
                <Table.HeaderCell width={1}>Confirm</Table.HeaderCell>
                <Table.HeaderCell width={1}>Download</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{tablebody}</Table.Body>
        </Table>
        </Segment>
        <Responsive minWidth={600}>
        <Grid>
        <Grid.Column width={2}>
        <Header as='h5' className='sizetag' >Page Size</Header>
        </Grid.Column>
        <Grid.Column width={6}>
        <Dropdown className='size' options={this.state.pageOptions} selection value={this.state.pageSize} 
        onChange={(event,data)=>this.changeSize(data.value)}/>
        </Grid.Column>
        {/* <Grid.Column width={2}>
        <Input
    label={{ basic: true, content: 'Go to page ' }}
    labelPosition='left'
    placeholder=''
    size='mini'
  />
  </Grid.Column> */}
  <Grid.Column width={8}>
        <Pagination
        className='pagi'
           activePage={this.state.activepage}
           onPageChange={this.handlePaginationChange}
            totalPages={this.state.totalpage}
            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
          />
          </Grid.Column>
        </Grid>
        </Responsive>
        <Responsive maxWidth={600}>
        <Grid>
        <Grid.Row>
        <Grid.Column width={6}>
        <Header as='h5' className='sizetag' >Page Size</Header>
        </Grid.Column>
        <Grid.Column width={10}>
        <Dropdown className='size' options={this.state.pageOptions} selection value={this.state.pageSize} 
        onChange={(event,data)=>this.changeSize(data.value)}/>
        </Grid.Column>
        </Grid.Row>
        <Grid.Row>  <Grid.Column width={16}>
        <Pagination
        className='pagi'
           activePage={this.state.activepage}
           onPageChange={this.handlePaginationChange}
            totalPages={this.state.totalpage}
            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
          />
          </Grid.Column>
        </Grid.Row>
        </Grid>
        </Responsive>
        </div> 
    );
  }
}
