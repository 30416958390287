import React, { Component } from 'react';
import { Grid, Button, Form, Header, Segment, Message,Modal } from 'semantic-ui-react';
import './ExternalLogin.css';
import Errorpop from "../error/Errorpop";

var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require('axios');

const  invalid_password_ERROR=<Message 
error
header='Invalid password'
content='The username/password combination is invalid, please try again!'
visible
 />;


 const  mismatch_password_ERROR=<Message 
error
header='Mismatch password'
content='The two passwords do not match, please try again!'
visible
 />;

 const  invalid_email_ERROR=<Message 
error
header='Invalid Email'
content='The Email is not valid or can not be found!'
visible
 />;

var crypto = require('crypto');


function createHash(password) {
  var salt = '39DFvnd03';
  var hash = md5(password + salt);
  return salt + hash;
}

function md5(string) {
  return crypto.createHash('md5').update(string).digest('hex');
}


class ExternalLogin extends Component {

    constructor(props) {
        super(props);
        console.log(props);
        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.closeself=this.closeself.bind(this);
        this.state = {
           username:'',
           password:'',
           message:'',
        login_step:0,
        pswd1:'',
        pswd2:'',
      pswd:'',
      isMobile: false};
        this.errorpop = React.createRef();
        this.updatePD1 = this.updatePD1.bind(this);
        this.updatePD2 = this.updatePD2.bind(this);
      }
updatePD1=(e, { name, value })=>{
  this.setState({pswd1:value,password1:createHash(value)})
}
updatePD2=(e, { name, value })=>{
  this.setState({pswd2:value,password2:createHash(value)})
}
//  checkPassword=async()=>{
//  const response=await 
//  fetch('/api/checkExtpswd', {
//    method: 'POST',
//    headers:{
//      'Accept': 'application/json',
//      'Content-Type': 'application/json'
//    },
//    body: JSON.stringify({
//      username:this.state.username,
//      password:this.state.password
//    })
//  });
//  const body=await response.json();
//  if (response.status !== 200) throw Error(body.message);
//  return body;
//  }
closeself() {
  this.setState({loggedin: true});
   this.props.loginUser(this.state.username);
}

  handleClick() 
   {
        // console.log(this.state.username+':'+this.state.password);
    axios.post('/api/checkExtpswd',{
      username:this.state.username,
      password:this.state.password
    }).then((res)=>{
        console.log(res);
    if (res.data.passed===true) 
    if (res.data.reset!==1) 
    {this.setState({loggedin: true});
    this.props.loginUser(res.data.id,res.data.custname,res.data.custid);
    }
    else
    {
        this.setState({login_step:1,message:''})
    }
    else 
    {
    this.setState({message:invalid_password_ERROR});
    }
    })
    }



    handleReset() 
    {
         // console.log(this.state.username+':'+this.state.password);
     if (this.state.password1!==this.state.password2) 
     {
     this.setState({message:mismatch_password_ERROR});
     }
     else 
     {
      axios.post('/api/updExtPswd',{
        email:this.state.username,
        password:this.state.password1
      }).then((res)=>{
        console.log(res);
        if (res.data.status==='ERROR') {this.errorpop.current.handleOpen();}
        else this.setState({login_step:2})
     })
     }
    }


    handleForgot = () => {
    this.setState({login_step:3,message:''})
    }


    sendPassword = () => {
      if (this.state.username==='')
      {
        this.setState({message:invalid_email_ERROR});
        }
      else 
      {
        axios.post('/api/checkExtUser',{
          email:this.state.username
        }).then((res)=>{
          console.log(res);
          if (res.data.status==='ERROR') {this.errorpop.current.handleOpen();}
          else 
          if (res.data[0].cnt===0) this.setState({message:invalid_email_ERROR});
          else 
          axios.post('/api/resetPassword',{
            email:this.state.username
          }).then((res)=>{
            console.log(res);
            if (res.data.status==='ERROR') {this.errorpop.current.handleOpen();}
            else this.setState({login_step:4})
          })
       })
      }
    }

  render() {
      // console.log(this.props.disabled);
   var loginForm='';
   if (this.state.login_step===0)
    {
        loginForm= 
        <div><Header as='h2' color='blue' textAlign='center'>
        Log-in to your account
      </Header>
      <Form size='large'>
        <Segment stacked>
        <Form.Input fluid icon='user' iconPosition='left' placeholder='User Name' 
        value={this.state.username}
        onChange = {(event,{ name, value }) => this.setState({username:value})}/>
        <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              value={this.state.pswd}
              onChange = {(event,{ name, value }) => this.setState({pswd:value,password:createHash(value)})
              }
            />
        <Button type='submit' fluid size='large' color='blue'
          onClick={this.handleClick}>Login</Button>
                <Button type='submit' fluid size='large' color='red' className='redbutton'
          onClick={this.handleForgot}>Forgot Password</Button>
          {this.state.message}
        </Segment>
      </Form>
</div> 
    }

    else 
    if (this.state.login_step===1)
    {
        loginForm= 
        <div> 
        <Header as='h2' color='blue' textAlign='center'>
        First time login or request for password reset. Please change Your Password
      </Header>
        <Form size='large'>
        <Segment stacked>
        <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              value={this.state.pswd1}
              onChange = {this.updatePD1
              }
            />
        <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              value={this.state.pswd2}
              onChange = {this.updatePD2
              }
            />
        <Button type='submit' fluid size='large' color='blue'
          onClick={this.handleReset}>Submit</Button>
          {this.state.message}
        </Segment>
      </Form>
      </div> 
    }
    else 
    if (this.state.login_step===2) loginForm= 
    <div> 
    <Header as='h2' color='blue' textAlign='center'>
    Thank you! Your password has been successfully updated.
  </Header>
    <Form size='large'>
    <Segment stacked>
    <Button type='submit' fluid size='large' color='blue'
      onClick={this.closeself}>Confirm</Button>
    </Segment>
  </Form>
  </div> ;
      else 
      if (this.state.login_step===3) loginForm= 
      <div> 
      <Header as='h2' color='blue' textAlign='center'>
      Please enter the email to reset the password
    </Header>
      <Form size='large'>
      <Segment stacked>
      <Form.Input fluid icon='user' iconPosition='left' placeholder='User Name' 
        value={this.state.username}
        onChange = {(event,{ name, value }) => this.setState({username:value})}/>
     
     <Button type='submit' fluid size='large' color='blue'
        onClick={this.sendPassword}>Submit</Button>
        {this.state.message}
      </Segment>
    </Form>
    </div> ;
    else    if (this.state.login_step===4) loginForm= 
    <div> 
    <Header as='h2' color='blue' textAlign='center'>
    An email has been sent to your email address containing a temporary password.
  </Header>
    <Form size='large'>
    <Segment stacked>
    <Button type='submit' fluid size='large' color='blue'
      onClick={()=>{this.setState({login_step:0})}}>Confirm</Button>
    </Segment>
  </Form>
  </div> ;

    return (
        <Modal className='loginModal' open={!this.props.disabled}>
        <div className='login-form'>
        <Grid className="logingrid" textAlign='center' verticalAlign='middle' centered columns={1}>
        <Grid.Column>
        <Header as='h1' color='black' textAlign='center'>
          Food Safety Research Laboratory
        </Header>
        <Header as='h1' color='black' textAlign='center'>
          Client Reporting System
        </Header>
       {loginForm}
      </Grid.Column>
      </Grid>
       </div>
       <Errorpop ref={this.errorpop} />
       </Modal>
    );
  }
}

export default ExternalLogin;