import React, { Component } from 'react';
import _ from 'underscore';
import {
   Table,
   Segment,
   Form,
   Dropdown,
   Button,
   Checkbox
 } from "semantic-ui-react";
 import "./ResultPlot.css";
 import Errorpop from "../error/Errorpop";
import moment from 'moment';
 
 var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require('axios');

export default class ResultPlot extends Component {
  constructor(props) {
    super(props);
    this.state={
      maxMonth:moment().format('YYYYMM'),
      allLoc:[],
      locPicks:[],
      resultData:[],
      fullResult:[],
      LineOption:[],
      ZoneOption:[],
      LocOption:[],
      selLine:"All Lines",
      selZone:"All Zones",
      selLoc:"All Locations",
      showPosOnly:false
    }
    this.errorpop = React.createRef();
  }


  getResults = () => {
    axios.get("/api/getResultForRpt?cust_id="+this.props.custid)
    .then(result => {
      if (result.data.status !== "ERROR") 
       {
        var mon=_.max(result.data,function(data){return data.mon_of_sample}).mon_of_sample;
        console.log(result.data);
        this.setState({maxMonth:mon,resultData:result.data, fullResult:result.data})
       }
      else 
       { console.log("error" + JSON.stringify(result));
       this.errorpop.current.handleOpen();
      //  this.delSample(key);
      }
    })
    .catch(function(error) {
      console.log(error);
    });
  }

  getLocations = () => {
    axios.get("/api/getLoc?custid="+this.props.custid)
    .then(result => {
      if (result.data.status !== "ERROR") 
       {
        var lineList=[{"text":"All Lines","value":"All Lines"}];
        _.uniq(_.pluck(result.data,'line_nm')).forEach((data,index)=>lineList.push({"text":data,"value":data}));
        var zoneList=[{"text":"All Zones","value":"All Zones"}];
        _.uniq(_.pluck(result.data,'zone_nm')).forEach((data,index)=>zoneList.push({"text":data,"value":data}));
        var locList=[{"text":"All Locations","value":"All Locations"}];
        _.uniq(_.pluck(result.data,'loc_nm')).forEach((data,index)=>locList.push({"text":data,"value":data}));
        this.setState({allLoc:_.sortBy(result.data,function(data){return data.line_nm+' '+data.zone_nm+' '+data.loc_nm}),
       lineOption:_.sortBy(lineList,'text'),
       zoneOption:_.sortBy(zoneList,'text'),
       LocOption:_.sortBy(locList,'text'),
       locPicks: _.sortBy(result.data,function(data){return data.line_nm+' '+data.zone_nm+' '+data.loc_nm})
         })
         
      }
      else 
       { console.log("error" + JSON.stringify(result));
       this.errorpop.current.handleOpen();
      //  this.delSample(key);
      }
    })
    .catch(function(error) {
      console.log(error);
    });
  }

  changeLine=(event,data)=>{
      console.log(data);
    var zoneList=[{"text":"All Zones","value":"All Zones"}];
   if (data.value==="All Lines") 
      _.uniq(_.pluck(this.state.allLoc,'zone_nm')).forEach((data,index)=>zoneList.push({"text":data,"value":data}));
   else
   _.uniq(_.pluck(_.where(this.state.allLoc,{"line_nm":data.value}),'zone_nm')).forEach((data,index)=>zoneList.push({"text":data,"value":data}));
      var locList=[{"text":"All Locations","value":"All Locations"}];
      if (data.value==="All Lines")     
    _.uniq(_.pluck(this.state.allLoc,'loc_nm')).forEach((data,index)=>locList.push({"text":data,"value":data}));
   else
   _.uniq(_.pluck(_.where(this.state.allLoc,{"line_nm":data.value}),'loc_nm')).forEach((data,index)=>locList.push({"text":data,"value":data}));
//    var resultData=[];
//    if (data.value==="All Lines") 
//      resultData=this.state.fullResult;
//     else 
//       locList.forEach((data,index)=>resultData.concat(_.where(this.state.fullResult,{loc_nm:data.text})));
var locPicks=[];
// console.log(this.state.allLoc);
     if (data.value==="All Lines") 
     locPicks=this.state.allLoc;
    else 
      locPicks=_.where(this.state.allLoc,{line_nm:data.value});
//  console.log(locPicks);
this.setState({zoneOption:_.sortBy(zoneList,'text'),locPicks:locPicks,
    LocOption:_.sortBy(locList,'text'),  selLine:data.value, selZone:"All Zones", selLoc:"All Locations"});
    
  }

  changeZone=(event,data)=>{
    console.log(data);
    var locPicks=[];
   var locList=[{"text":"All Locations","value":"All Locations"}];
   if (this.state.selLine==="All Lines")
    if (data.value==="All Zones")     
  { locPicks=this.state.allLoc;
    _.uniq(_.pluck(this.state.allLoc,'loc_nm')).forEach((data,index)=>locList.push({"text":data,"value":data}));
  }
  else
  {
 _.uniq(_.pluck(_.where(this.state.allLoc,{"zone_nm":data.value}),'loc_nm')).forEach((data,index)=>locList.push({"text":data,"value":data}));
  locPicks=_.where(this.state.allLoc,{zone_nm:data.value});
  }
 else 
 if (data.value==="All Zones")     
  { _.uniq(_.pluck(_.where(this.state.allLoc,{"line_nm":this.state.selLine}),'loc_nm')).forEach((data,index)=>locList.push({"text":data,"value":data}));
  locPicks=_.where(this.state.allLoc,{line_nm:this.state.selLine});
}
  else
 { _.uniq(_.pluck(_.where(this.state.allLoc,{"line_nm":this.state.selLine,"zone_nm":data.value}),'loc_nm')).forEach((data,index)=>locList.push({"text":data,"value":data}));
 locPicks=_.where(this.state.allLoc,{"line_nm":this.state.selLine,"zone_nm":data.value});
}

 this.setState({ locPicks:locPicks,
  LocOption:_.sortBy(locList,'text'),  selZone:data.value, selLoc:"All Locations"});

}


changeLoc=(event,data)=>{
    var locPicks=[];
    if (this.state.selLine==='All Lines') locPicks=this.state.allLoc;
    else locPicks=_.where(this.state.allLoc,{line_nm:this.state.selLine});
    if (this.state.selZone==='All Zones') locPicks=locPicks;
    else locPicks=_.where(locPicks,{zone_nm:this.state.selZone});
    locPicks=_.where(locPicks,{loc_nm:data.value});
    this.setState({selLoc:data.value,locPicks:locPicks});
}

togglePos=()=>{
    this.setState({showPosOnly:!this.state.showPosOnly});
}

resetOptions=()=>{
    var lineList=[{"text":"All Lines","value":"All Lines"}];
        _.uniq(_.pluck(this.state.allLoc,'line_nm')).forEach((data,index)=>lineList.push({"text":data,"value":data}));
        var zoneList=[{"text":"All Zones","value":"All Zones"}];
        _.uniq(_.pluck(this.state.allLoc,'zone_nm')).forEach((data,index)=>zoneList.push({"text":data,"value":data}));
        var locList=[{"text":"All Locations","value":"All Locations"}];
        _.uniq(_.pluck(this.state.allLoc,'loc_nm')).forEach((data,index)=>locList.push({"text":data,"value":data}));
        this.setState({
       lineOption:_.sortBy(lineList,'text'),
       zoneOption:_.sortBy(zoneList,'text'),
       LocOption:_.sortBy(locList,'text'),
       locPicks: _.sortBy(this.state.allLoc,function(data){return data.line_nm+' '+data.zone_nm+' '+data.loc_nm}),
       selLine:"All Lines",selZone:"All Zones",selLoc:"All Locations", showPosOnly:false})
}



  componentWillMount(){
    this.getResults();
    this.getLocations();
  }

  render() {
  var monthList=[];
  for (var i=0;i<12;i++)
  monthList.unshift(<Table.HeaderCell width={1}>{moment(this.state.maxMonth,"YYYYMM").subtract(i,"months").format("MMM YYYY")}</Table.HeaderCell>)
  
  var rowlist=[];
  this.state.locPicks.forEach((data,index)=>{
    var dataList=[];
    var totalpos=0;
    var totalneg=0;
    for (var i=11;i>=0;i--)
    {
    var mon_to_search=moment(this.state.maxMonth,"YYYYMM").subtract(i,"months").format("YYYYMM");
    var countrow=_.where(this.state.resultData,{loc_cd:data.loc_cd,mon_of_sample:mon_to_search})[0];
    dataList.push(<Table.Cell>
      <div className={countrow && countrow.sum_pos>0?'postext':''}>{countrow?'+'+countrow.sum_pos:''}</div>
      <div className='negtext'>{countrow?'-'+countrow.sum_neg:''}</div>
      </Table.Cell>);
      totalpos+=countrow?countrow.sum_pos:0;
      totalneg+=countrow?countrow.sum_neg:0;
    }
    var summaryCell=
    <Table.Cell>
    <div className={totalpos>0?'postext':''}>+{totalpos}</div>
    <div className='negtext'>-{totalneg}</div>
    </Table.Cell>;
    if (this.state.showPosOnly===false || totalpos>0)
    rowlist.push(
      <Table.Row key={'datarow'+index}>
      <Table.Cell>{data.line_nm}</Table.Cell>
      <Table.Cell>{data.zone_nm}</Table.Cell>
      <Table.Cell>{data.loc_nm}</Table.Cell>
      <Table.Cell>{data.loc_desc}</Table.Cell>
      {dataList}
      {summaryCell}
      </Table.Row> 
    )
  })
  
  var TableReport=
  <Table celled size='small'>
  <Table.Header>
    <Table.Row>
    <Table.HeaderCell width={1}>Line</Table.HeaderCell>
    <Table.HeaderCell width={1}>Zone</Table.HeaderCell>
    <Table.HeaderCell width={1}>Location</Table.HeaderCell>
      <Table.HeaderCell width={2}>Description</Table.HeaderCell>
      {monthList}
      <Table.HeaderCell  width={1}>Total</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
  <Table.Body>
  {rowlist}
  </Table.Body>
  </Table>;
  return (<Segment className='mainseg'>
  <Form>
      <Form.Group>
    <Form.Field width={4}>
      <label>Line</label>
      <Dropdown fluid selection options={this.state.lineOption}
      value={this.state.selLine} onChange={this.changeLine}/>
    </Form.Field>
    <Form.Field width={5}>
      <label>Zone</label>
      <Dropdown fluid selection options={this.state.zoneOption}
      value={this.state.selZone} onChange={this.changeZone}/>
    </Form.Field>
    <Form.Field width={5}>
      <label>Location</label>
      <Dropdown fluid selection options={this.state.LocOption}
      value={this.state.selLoc} onChange={this.changeLoc}/>
    </Form.Field>
    <Form.Field width={2}>
        <label>Show Positive Only</label>
        <Checkbox toggle checked={this.state.showPosOnly} onChange={this.togglePos}/>
    </Form.Field>
        <Button primary onClick={this.resetOptions} >Reset</Button>
    </Form.Group>
  </Form>
  {TableReport}
  <Errorpop ref={this.errorpop} />
  </Segment>);
  
  }
}