import React, { Component } from 'react';
import './Help.css';

import {
Header,
Grid,
Icon
 } from "semantic-ui-react";



export default class Help extends Component {

  render() {
  return <Grid>
     <Grid.Row>
         <Header as='h2' color='blue'>
         Welcome to the Food Safety Lab Client Application! 
         </Header>
     </Grid.Row>
     <Grid.Row>
         This portal enables our customers to perform self-service activities including online sample submission, past test results search and download, location management and location based result trend analysis.
         <br/> This portal works best (visually and functionally) with Google Chrome, Firefox, or Microsoft Edge browser. It works with Internet Explorer 10 and 11, but some parts may not display in the best way. It does not work in IE 9 and below.
    </Grid.Row>
    <Grid.Row>
    <Grid.Column width={2}>
      <Icon name='map' size='massive'/>
    </Grid.Column>
    <Grid.Column width={6}>
    <Grid.Row>
    <Header as='h3' color='blue'>
         Location Management 
         </Header>
    </Grid.Row>
    <Grid.Row>
        This page allow you to import existing location spreadsheet and manual manage (add, delete, edit) current location hierarchy.
        <br/> The following videos will guide you through the functionality.
        <br/> To learn about how to import location hierarchy from spreadsheet, watch <a href="https://youtu.be/cmswbX1niF0" target="_blank"  rel="noopener noreferrer">this video</a>
        <br/> To learn about how to manually manage location hierarchy, watch <a href="https://youtu.be/6GGWw94MMas" target="_blank"  rel="noopener noreferrer">this video</a> 
    </Grid.Row>
    </Grid.Column>
    <Grid.Column width={2}>
      <Icon name='shopping cart' size='massive'/>
    </Grid.Column>
    <Grid.Column width={6}>
    <Grid.Row>
    <Header as='h3' color='blue'>
         Sample Submission
         </Header>
    </Grid.Row>
    <Grid.Row>
Coming Soon    </Grid.Row>
    </Grid.Column>
    </Grid.Row>
    <Grid.Row>
    <Grid.Column width={2}>
      <Icon name='clipboard list' size='massive'/>
    </Grid.Column>
    <Grid.Column width={6}>
    <Grid.Row>
    <Header as='h3' color='blue'>
         Past Result
         </Header>
    </Grid.Row>
    <Grid.Row>
  This page allows to browse and search the results from all the past sample submissions. You can also download the past report from this page. 
  <br/> For a demo on the function, please watch <a href="https://youtu.be/-XGY8Mx5S6c" target="_blank"  rel="noopener noreferrer">this video</a>   </Grid.Row>
    </Grid.Column>
    <Grid.Column width={2}>
      <Icon name='chart bar' size='massive'/>
    </Grid.Column>
    <Grid.Column width={6}>
    <Grid.Row>
    <Header as='h3' color='blue'>
         Report Dashboard 
         </Header>
    </Grid.Row>
    <Grid.Row>
     Coming Soon
    </Grid.Row>
    </Grid.Column>
    </Grid.Row>
      </Grid>
  }
}