import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import MainMenu from './Menu/MainMenu';
import Placeholder from './Menu/Placeholder';
import * as serviceWorker from './serviceWorker';
var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require('axios');

axios.get('/api/getsitestate').then(res=>{
    if (res.data[0].SITE_STATUS==='ON')
ReactDOM.render(<MainMenu />, document.getElementById('root'));
else ReactDOM.render(<Placeholder />, document.getElementById('root'));
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


