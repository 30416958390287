import React, { Component } from "react";
import {
  Header,
  Button,
  Image,
  Dropdown,
  Menu,
  Container,
  // Grid,
  Label,
  // GridRow,
  Icon,
  Responsive
} from "semantic-ui-react";

import "./Menu.css";
// import _ from "underscore";
// import moment from "moment";
import ExternalLogin from "../External/ExternalLogin";
import External from "../External/External";
import ManageLoc from "../Location/ManageLoc";
import ReceivePage from "../ReceivePage/ReceivePage";
import Help from "../Help/Help";
import ReviewSub from "../ReviewSub/ReviewSub"
import LatestTest from "../LatestTest/LatestTest"
import ResultPlot from "../Report/ResultPlot"
var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require('axios');

export default class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedin:false,
      page:<Help/>
        };
    this.errorpop = React.createRef();
    this.loginUser=this.loginUser.bind(this);
    }

 loginUser(username,custname,custid) {
   console.log(username);
   this.setState({loggedin:true,username:username,custname:custname,custid:custid});
   const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
   console.log(vw)
   if  (vw<=600) 
   this.setState({ page: <External /> });
   }

 logout = () => {
  axios.get('/api/extlogout').then(res=>{
    this.setState({username:''},()=>window.location.reload());
  });
};

jumpToSubmission = () => {
  this.setState({ page: <ReceivePage custid={this.state.custid} custnm={this.state.custname}/> });
}

handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    if (name === "rptSample")
      this.setState({ page: <External /> });
    else if (name==="reviewSample")
    this.setState({ page: <ReviewSub custid={this.state.custid} custnm={this.state.custname} onReset={this.jumpToSubmission}/> });
     else if (name === "help")
      this.setState({ page: <Help /> });
    else     if (name === "mngloc")
    this.setState({ page: <ManageLoc custid={this.state.custid} custnm={this.state.custname}/> });
    else     if (name === "sbmtSample")
    this.setState({ page: <ReceivePage custid={this.state.custid} custnm={this.state.custname}/> });
    else     if (name === "rptloc")
    this.setState({ page: <ResultPlot custid={this.state.custid} custnm={this.state.custname}/> });
    else     if (name === "latesttest")
    this.setState({ page: <LatestTest custid={this.state.custid} custnm={this.state.custname}/> });
    // else if (name === "startproc") this.setState({ page: <StartProcess /> });
  };


  componentDidMount() {
    axios.get('/api/getextuser').then(res=>{
      console.log(res.data);
      this.setState({loggedin:res.data.loggedin});
       if (res.data.loggedin) 
         { var username=res.data.name;
           this.setState({username:username,custname:res.data.custname,custid:res.data.custid});
           const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
           console.log(vw)
           if  (vw<=600) 
           this.setState({ page: <External /> });
        }
      console.log(this.state.loggedin);
    })
    
   }


  render() {
    const { activeItem } = this.state;

    return (
      <div>
        <link
          rel="stylesheet"
          href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.0/semantic.min.css"
        />        <ExternalLogin disabled={this.state.loggedin} loginUser={this.loginUser}/>
      <Responsive minWidth={600}>
             <Menu fixed="top" borderless icon='labeled'>
             {/* <div className="corpHeader"> */}
           <Header className="corpHeader" as="h3" >
              <Image
                size="small"
                src="Corp_Icon_Header.jpg"
                style={{ marginRight: "1.5em" }}
              />
             <div className='headertxt'> FSR Laboratory<br/> Client Application</div>
            </Header>
            {/* </div> */}
            <Container>
            {/* <Menu icon='labeled' > */}
        <Menu.Item name='mngloc' active={activeItem === 'mngloc'} onClick={this.handleItemClick}>
          <Icon name='map' />
          <div className='menuItem'>Location<br/>Management</div>
        </Menu.Item>

        {/* <Menu.Item
          name='sbmtSample'
          active={activeItem === 'sbmtSample'}
          onClick={this.handleItemClick}
        >
          <Icon name='shopping cart' />
          <div className='menuItem'> Submit<br/>Sample</div>
  </Menu.Item> */}
  <Menu.Item>
        {/* <Icon name='shopping cart' />
          <div className='menuItem'> Submit<br/>Sample</div> */}
    <Dropdown text={     <div className='menuIcon'> <Icon name='shopping cart' />
          <div className='menuItem'> Submit<br/>Sample</div></div>} icon={null}> 
      <Dropdown.Menu>
        <Dropdown.Item
        name='sbmtSample'
                  active={activeItem === 'sbmtSample'}
                  onClick={this.handleItemClick}
        >Add</Dropdown.Item>
        <Dropdown.Item
                name='reviewSample'
                active={activeItem === 'reviewSample'}
                onClick={this.handleItemClick}
                >Review</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    </Menu.Item>

        <Menu.Item
          name='rptSample'
          active={activeItem === 'rptSample'}
          onClick={this.handleItemClick}
        >
          <Icon name='clipboard list' />
          <div className='menuItem'>Past<br/>Result</div>
        </Menu.Item>

          <Menu.Item
          name='rptloc'
          active={activeItem === 'rptloc'}
          onClick={this.handleItemClick}
        >
          <Icon name='chart bar' />
          <div className='menuItem'>Report<br/>Dashboard</div>
        </Menu.Item>    
        <Menu.Item
          name='latesttest'
          active={activeItem === 'latesttest'}
          onClick={this.handleItemClick}
        >
          <Icon name='calendar alternate outline' />
          <div className='menuItem'>Latest<br/>Test</div>
        </Menu.Item>    

        <Menu.Item
          name='help'
          active={activeItem === 'help'}
          onClick={this.handleItemClick}
        >
          <Icon name='question' />
          <div className='menuItem'>Help</div>
        </Menu.Item>    
            </Container>
            <div className="uName">
            <Button as="div" labelPosition="left">
              <Label basic className="loginTag">
                 {this.state.custname} 
                <br/> Logged in as {this.state.username}
              </Label>
              <Button color="red" onClick={this.logout}>
                Logout
              </Button>
            </Button>
            </div>
           </Menu>
          </Responsive>
           <Responsive maxWidth={600}>
           <Menu fixed="top">
           <Menu.Item >
          <Icon name="sidebar" />
        </Menu.Item>
        <Menu.Item>
           <Header className="corpHeader" as="h3" >
              <Image
                size="mini"
                src="Corp_Icon_Header.jpg"
                style={{ marginRight: "0.5em" }}
              />
             <div className='headertxt'> FSR Laboratory Client Application</div>
            </Header>
            </Menu.Item>
            </Menu>
             </Responsive>
          <Container style={{ marginTop: "7em" , height:'100vh'}}>
{this.state.page}
          </Container>
      </div>
    );
  }
}
