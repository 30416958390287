import React, { Component } from 'react';
import SortableTree from 'react-sortable-tree';
import {changeNodeAtPath,addNodeUnderParent,getFlatDataFromTree } from './tree-data-utils'
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import './ManageLoc.css';
import _ from 'underscore';
import {
   Grid,
   Button,
   Icon,
   Form,
   Input,
   Label,
   Confirm,
   Modal,
   Header,
   TextArea,
   Popup,
   Segment,
   Dimmer,
   Loader,
   Table,
   Checkbox
 } from "semantic-ui-react";

 import Errorpop from "../error/Errorpop";
 import moment from 'moment';
 import FileButton from './FileButton';  
 import Papa from 'papaparse'; 

 var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require('axios');

export default class ManageLoc extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      treeData: [],
      visible:true,
      showedit:false,
      confirmopen: false,
      max_site_cd: 0,
      max_line_cd: 0,
      max_zone_cd: 0,
      max_loc_cd: 0,
      changed: false,
      confirmsave: false,
      savingInProgress: false,
      confirmreset: false,
      canAddNewSite: false,
      appendImport:false
    };

    this.convertTreeData=this.convertTreeData.bind(this);
    this.removeNode=this.removeNode.bind(this);
    this.inputRef=null;
    this.saveData=null;
    this.errorpop = React.createRef();
  }

  handleClose = () => {
      this.setState({showedit:false, importing:false})
  }
 
  componentWillMount() 
  {
    
//     var treeData=[{site_cd:1,site_nm:'New York', line_cd: 1, line_nm:'SAUCE LINE', zone_cd:1, zone_nm:'Zone 1',loc_cd: 1,loc_nm:'Z1.1.V1',loc_desc:'Robot coup mixer large-  blades,  inside of the filler, Inside cover of the robot coupe mixer'},
//     {site_cd:1,site_nm:'New York',  line_cd: 1, line_nm:'SAUCE LINE',zone_cd:1, zone_nm:'Zone 1',loc_cd:2, loc_nm:'Z1.2.V1',loc_desc:'Robot coup mixer small -  blades,  inside of the filler, Inside cover of the robot coupe mixer'},
//     {site_cd:1,site_nm:'New York', line_cd: 2, line_nm:'BASKET FRYER',zone_cd:1, zone_nm:'Zone 1',loc_cd: 1, loc_nm:'Z1.8.V1',loc_desc:'Basket fryer #1 – Inside  of basket fryer , Basket of basket fryer'},
//     {site_cd:1,site_nm:'New York',  line_cd: 1, line_nm:'SAUCE LINE',zone_cd:2, zone_nm:'Zone 2',loc_cd: 1,loc_nm:'Z2.1.V1',loc_desc:'Robot coupe mixer large  - Control panel'},
//     {site_cd:2,site_nm:'New Jersey',  line_cd: 3, line_nm:'SAUSAGE LINE',zone_cd:2, zone_nm:'Zone 2',loc_cd: 1,loc_nm:'Z2.1.V1',loc_desc:'Robot coupe mixer large  - Control panel'}
// ];
axios.get('/api/getLoc?custid='+this.props.custid).then(res=>{
    console.log(res.data);
var treeData=res.data;
if (treeData.length===0) this.setState({canAddNewSite:true});
// console.log(_.pluck(treeData,'site_cd'));
this.setState({treeData:this.convertTreeData(treeData), 
    max_site_cd:_.pluck(treeData,'site_cd').length>0?_.max(_.pluck(treeData,'site_cd')):0,
    max_line_cd:_.pluck(treeData,'line_cd').length>0?_.max(_.pluck(treeData,'line_cd')):0,
    max_zone_cd:_.pluck(treeData,'zone_cd').length>0?_.max(_.pluck(treeData,'zone_cd')):0,
    max_loc_cd:_.pluck(treeData,'loc_cd').length>0?_.max(_.pluck(treeData,'loc_cd')):0
     });
    })
  }

  resetTreeData = () => {
      this.setState({confirmreset:true, confirmMsg:'Please confirm if you want to reset the location to previous saved version. All changes will be lost'})

  }

  confirmResetTree = () => {
    axios.get('/api/getLoc?custid='+this.props.custid).then(res=>{
        console.log(res.data);
    var treeData=res.data;
    // console.log(_.pluck(treeData,'site_cd'));
    this.setState({confirmreset:false, changed:false,treeData:this.convertTreeData(treeData), 
        max_site_cd:_.pluck(treeData,'site_cd').length>0?_.max(_.pluck(treeData,'site_cd')):0,
        max_line_cd:_.pluck(treeData,'line_cd').length>0?_.max(_.pluck(treeData,'line_cd')):0,
        max_zone_cd:_.pluck(treeData,'zone_cd').length>0?_.max(_.pluck(treeData,'zone_cd')):0,
        max_loc_cd:_.pluck(treeData,'loc_cd').length>0?_.max(_.pluck(treeData,'loc_cd')):0
         });
        })
  }

  convertTreeData = (treeData) => {
   var sortedData=_.sortBy(treeData,function(item){return [item.site_cd,item.line_cd,item.zone_cd,item.loc_cd]});
   var newtreeData=[];
   var currSite=null;
   var currLine=null;
   var currZone=null;
   console.log(sortedData);
   for (var i=0;i<sortedData.length;i++) {
   if (i===0) 
     {
        currSite={expanded:true,site_cd:sortedData[0].site_cd,type:'site',title:sortedData[0].site_nm,children:[]};
        if (sortedData[0].line_cd)
        currLine={expanded:true,line_cd:sortedData[0].line_cd,type:'line',title:sortedData[0].line_nm,children:[]};
        if (sortedData[0].zone_cd)
        currZone={expanded:false,zone_id:sortedData[0].site_cd+'.'+sortedData[0].line_cd+'.'+sortedData[0].zone_cd,zone_cd:sortedData[0].zone_cd,type:'zone',title:sortedData[0].zone_nm,children:[]};
        if (sortedData[0].loc_cd) 
        currZone.children.push({type:'loc',title:sortedData[0].loc_nm,loc_cd:sortedData[0].loc_cd,desc:sortedData[0].loc_desc});
     }

   else {

    if (sortedData[i].site_cd!==currSite.site_cd) 
    {   
      if (currZone) currLine.children.push(currZone);
      if (currLine) currSite.children.push(currLine);
      newtreeData.push(currSite);
      currSite={expanded:true,site_cd:sortedData[i].site_cd,type:'site',title:sortedData[i].site_nm,children:[]};
      currLine=null;
      currZone=null;   
      if (sortedData[i].line_cd)
      currLine={expanded:true,line_cd:sortedData[i].line_cd,type:'line',title:sortedData[i].line_nm,children:[]};
      if (sortedData[i].zone_cd)
      currZone={expanded:false,zone_id:sortedData[i].site_cd+'.'+sortedData[i].line_cd+'.'+sortedData[i].zone_cd,zone_cd:sortedData[i].zone_cd,type:'zone',title:sortedData[i].zone_nm,children:[]};
      if (sortedData[i].loc_cd) 
      currZone.children.push({type:'loc',title:sortedData[i].loc_nm,loc_cd:sortedData[i].loc_cd,desc:sortedData[i].loc_desc});
     }
    else 
    if (sortedData[i].line_cd!==currLine.line_cd) 
    {  if (currZone) currLine.children.push(currZone);
      currSite.children.push(currLine);
      currLine={expanded:true,line_cd:sortedData[i].line_cd,type:'line',title:sortedData[i].line_nm,children:[]}
      currZone=null;
      if (sortedData[i].zone_cd)
      currZone={expanded:false,zone_id:sortedData[i].site_cd+'.'+sortedData[i].line_cd+'.'+sortedData[i].zone_cd,zone_cd:sortedData[i].zone_cd,type:'zone',title:sortedData[i].zone_nm,children:[]};
      if (sortedData[i].loc_cd) 
      currZone.children.push({type:'loc',title:sortedData[i].loc_nm,loc_cd:sortedData[i].loc_cd,desc:sortedData[i].loc_desc});
    }
     else
     if (sortedData[i].zone_cd!==currZone.zone_cd) 
        {   currLine.children.push(currZone);
            currZone={expanded:false,zone_id:sortedData[i].site_cd+'.'+sortedData[i].line_cd+'.'+sortedData[i].zone_cd,zone_cd:sortedData[i].zone_cd,type:'zone',title:sortedData[i].zone_nm,children:[]};
            if (sortedData[i].loc_cd) 
            currZone.children.push({type:'loc',title:sortedData[i].loc_nm,loc_cd:sortedData[i].loc_cd,desc:sortedData[i].loc_desc});
         }
    else currZone.children.push({type:'loc',title:sortedData[i].loc_nm,loc_cd:sortedData[i].loc_cd,desc:sortedData[i].loc_desc});
    }
    // console.log(i);
    // console.log(currSite);
    // console.log(currLine);
    // console.log(currZone);    
      }
   if (currZone) currLine.children.push(currZone);
   if (currLine) currSite.children.push(currLine);
   if (currSite) newtreeData.push(currSite);
  //  console.log(treeData);
   return newtreeData;
  }

flattenTreeData=(treeData)=>{
    const getNodeKey = ({ treeIndex }) => treeIndex;
    var flatData=getFlatDataFromTree({
        treeData,
        getNodeKey,
        ignoreCollapsed: false
    })
    var dbrecord={};
    var dataset=[];
   flatData.forEach((data,index)=>{
       if (data.node.type==='site')
       { dbrecord.site_cd=data.node.site_cd; dbrecord.site_nm=data.node.title;
        dbrecord.line_cd=null; dbrecord.line_nm=null; dbrecord.zone_cd=null; dbrecord.zone_nm=null;
            dbrecord.loc_cd=null;dbrecord.loc_nm=null;dbrecord.loc_desc=null;

        if (index===flatData.length-1 || flatData[index+1].node.type!=='line')
        {   
            dataset.push(_.clone(dbrecord)); }
        }
       else if (data.node.type==='line')
       {dbrecord.line_cd=data.node.line_cd; dbrecord.line_nm=data.node.title; 
        dbrecord.zone_cd=null; dbrecord.zone_nm=null;
            dbrecord.loc_cd=null;dbrecord.loc_nm=null;dbrecord.loc_desc=null;
        if (index===flatData.length-1 || flatData[index+1].node.type!=='zone')
        {
            dataset.push(_.clone(dbrecord)); }
       }      
       else if (data.node.type==='zone')
       {dbrecord.zone_cd=data.node.zone_cd; dbrecord.zone_nm=data.node.title;  
        dbrecord.loc_cd=null;dbrecord.loc_nm=null;dbrecord.loc_desc=null;
        if (index===flatData.length-1 || flatData[index+1].node.type!=='loc')
        {
            dataset.push(_.clone(dbrecord)); }
    }
       else 
       {dbrecord.loc_cd=data.node.loc_cd; dbrecord.loc_nm=data.node.title; 
        dbrecord.loc_desc=data.node.desc?data.node.desc:'';
        dataset.push(_.clone(dbrecord)); }

});
return(dataset);
}

saveTreeData=(treeData)=>{
this.saveData=this.flattenTreeData(treeData);
// console.log(this.saveData);
this.setState({confirmsave:true,confirmMsg:'All the changes you made will be saved, this can not be undone.'})
}

confirmSave=(saveData)=>{
    var payload=[];
    this.setState({savingInProgress:true});
    saveData.forEach((data,index)=>{
      payload.push("("+this.props.custid+","+data.site_cd+",'"+data.site_nm.replace("\\","\\\\").replace("'","\\'")
      +"',"+data.line_cd+",'"+data.line_nm.replace("\\","\\\\").replace("'","\\'")+"',"+data.zone_cd+",'"+data.zone_nm.replace("\\","\\\\").replace("'","\\'")
      +"',"+data.loc_cd+",'"+data.loc_nm.replace("\\","\\\\").replace("'","\\'")+"','"+data.loc_desc.replace("\\","\\\\").replace("'","\\'")+"')")
    })
    // console.log(payload);
    axios.post('/api/saveLoc',{custid:this.props.custid, payload:payload.join(",")}).then(res=>
      {
        if (res.data.status==='ERROR') {this.errorpop.current.handleOpen();}
        else this.setState({changed:false,savingInProgress:false})
      })
    this.setState({confirmsave:false});
}

  removeNode=(node)=>{
    
    // console.log(node);
    var msg='';
    if (node.node.type==='site') msg='This action will delete all zone/line/locations under '+node.node.title;
    else if (node.node.type==='zone') msg='This action will delete all line/locations under '+node.node.title;
    else if (node.node.type==='line') msg='This action will delete all locations under '+node.node.title;
    else msg='This action will delete location '+node.node.title;
    this.setState({confirmopen:true, nodeToDelete: node, confirmMsg: msg})

  }

  confirmRemoveNode=()=>{
      var node=this.state.nodeToDelete;
    var treeData=this.state.treeData.slice();
    console.log(treeData);
    console.log(node);
    treeData.forEach((site,index)=> {
      if (node.node.type==='site' && node.node.site_cd===site.site_cd) treeData.splice(index,1); 
      else site.children.forEach((line,index) => {
        if (node.node.type==='line' && node.node.line_cd===line.line_cd && node.parentNode.site_cd===site.site_cd) site.children.splice(index,1); 
        else line.children.forEach((zone,index)=>{
         if (node.node.type==='zone' && node.node.zone_id===zone.zone_id) line.children.splice(index,1); 
         else zone.children.forEach((loc,index)=>{
           if (node.node.type==='loc' && node.parentNode.zone_id===zone.zone_id && node.node.loc_cd===loc.loc_cd) zone.children.splice(index,1); 
         })
        })
      })
    })
   //  console.log(treeData);
    this.setState({treeData:treeData,visible:true,confirmopen:false,changed: true});
  }

  addSite=()=>{

    var newNode={};
        newNode.title='New Site'; newNode.type='site';
        newNode.site_cd=this.state.max_site_cd+1;
         this.setState({max_site_cd:newNode.site_cd});
    newNode.tempTitle=newNode.title;
    newNode.updating=true;
    newNode.children=[];
    var treeData=this.state.treeData;
    const getNodeKey = ({ treeIndex }) => treeIndex;
var newTree=addNodeUnderParent({
 treeData: treeData,
 newNode,
 parentKey: null,
 getNodeKey,
 expandParent: true
})
// console.log(newTree);
this.setState({treeData:newTree.treeData, editing:true,changed: true});
}


  addNode=(node)=>{
    //   console.log(node);
      var newNode={};
      if (node.node.type==='site') 
      {newNode.title='New Line'; newNode.type='line';
      newNode.line_cd=this.state.max_line_cd+1;
      newNode.children=[];
       this.setState({max_line_cd:newNode.line_cd})}
      else if (node.node.type==='line') 
      { newNode.title='New Zone'; newNode.type='zone';
      newNode.zone_cd=this.state.max_zone_cd+1;
      newNode.children=[];
      newNode.zone_id=node.parentNode.site_cd+'.'+node.node.line_cd+'.'+newNode.zone_cd
      this.setState({max_zone_cd:newNode.zone_cd })}
      else if (node.node.type==='zone') 
      { newNode.title='New Location'; newNode.type='loc';
      newNode.loc_cd=this.state.max_loc_cd+1;
      this.setState({max_loc_cd:newNode.loc_cd })}
      newNode.tempTitle=newNode.title;
      newNode.desc="Location Description";
    //  newNode.updating=true;
      var treeData=this.state.treeData;
      const getNodeKey = ({ treeIndex }) => treeIndex;
  var newTree=addNodeUnderParent({
   treeData: treeData,
   newNode,
   parentKey: node.treeIndex,
   getNodeKey,
   expandParent: true
  })
//   console.log(newTree);
  this.setState({treeData:newTree.treeData, editing:false,changed: true});
}

  editNode=(node)=>{
  this.setState({showedit:true,editNode:node, editDesc:node.node.desc,changed: true})
  }

  exportToCSV =()=>{
   var flatData=this.flattenTreeData(this.state.treeData);
   let csvData = [];
   flatData.forEach((data,index)=>{
       csvData.push({test_nm:(data.site_nm?data.site_nm:''),
       line_nm:(data.line_nm?data.line_nm:''), zone_nm:(data.zone_nm?data.zone_nm:''),
       loc_nm:(data.loc_nm?data.loc_nm:''),loc_desc:(data.loc_desc?data.loc_desc:'')
   })
})
   let csvContent=Papa.unparse(csvData);
   let filename="location_export_"+moment().format('MMDDYY')+".csv";
   var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
   if (navigator.msSaveBlob) { // IE 10+
       navigator.msSaveBlob(blob, filename);
   } else {
       var link = document.createElement("a");
       if (link.download !== undefined) { // feature detection
           // Browsers that support HTML5 download attribute
           var url = URL.createObjectURL(blob);
           link.setAttribute("href", url);
           link.setAttribute("download", filename);
           link.style.visibility = 'hidden';
           document.body.appendChild(link);
           link.click();
           document.body.removeChild(link);
       }
   }
  }

  startUpdateTitle = (event,node) => {
    //   console.log(node);
    //   console.log(event);
      var path=node.path;
      var newNode=node.node;
      newNode.updating=true;
      newNode.tempTitle=newNode.title;
      const getNodeKey = ({ treeIndex }) => treeIndex;
      var treeData= changeNodeAtPath({
        treeData: this.state.treeData,
        path,
        getNodeKey,
        newNode: newNode,
      });
      this.setState({treeData:treeData,editing:true,changed: true});
  }

  titleKeyDown = (event,data,node) => {
    var path=node.path;
    var newNode=node.node;
      if (event.key==='Enter')
      {
        newNode.title=newNode.tempTitle;
        newNode.updating=false;     
        this.setState({editing:false,changed: true});
      }
      else if(event.key==='Escape')
       {
         newNode.updating=false;
         this.setState({editing:false});
       }
       const getNodeKey = ({ treeIndex }) => treeIndex;
       this.setState(state => ({
         treeData: changeNodeAtPath({
           treeData: state.treeData,
           path,
           getNodeKey,
           newNode: newNode,
         }),}));
    //   event.preventDefault();
  }

  updateTitle = (event,data,node) => {
    //   console.log(data);
      var path=node.path;
      var newNode=node.node;
      newNode.tempTitle=data.value;
      const getNodeKey = ({ treeIndex }) => treeIndex;
      this.setState(state => ({
        treeData: changeNodeAtPath({
          treeData: state.treeData,
          path,
          getNodeKey,
          newNode: newNode,
        }),}));
  }


//   componentDidUpdate(){
//       console.log(this.inputRef);
//      if (this.inputRef) 
//        { this.inputRef.focus();
//         window.scrollTo({
//             top:this.inputRef.offsetTop
//         })
//        }
//   }

  setInputRef = element =>{
    //   console.log(element);
      if (element) {
      this.inputRef=element;
      this.inputRef.focus();
      window.scrollTo({
        top:this.inputRef.offsetTop
    })
}
  }

  saveDesc = (e,data) =>{

      this.setState({editDesc:data.value,changed: true})
  }

  updateDesc = () => {
      var newNode=this.state.editNode;
      newNode.node.desc=this.state.editDesc;
      this.setState({editNode:newNode,showedit:false,changed: true});
  }


  readOnLoad = file => (e) => {
    var contents = Papa.parse(e.target.result); 
    console.log(contents);
    var header= contents.data[0];
    console.log(header);
    if (header.join(',').toLowerCase().search("test_nm,line_nm,zone_nm,loc_nm,loc_desc")!==0)
    this.setState({importStep:3})
    else 
    {
        var importData=[];
        contents.data.forEach((value,index)=>{
            let dup=false;
            if (value.length>=3 && (_.filter(contents.data,function(item){return item.length>=3 && item[3].toUpperCase().trim()===value[3].toUpperCase().trim()}).length>1)) 
            dup=true;
            if (index>0 && value.length>=5 && value[0]!=='')
importData.push({dup:dup, site_nm:value[0].toUpperCase().trim(), line_nm:value[1].toUpperCase().trim(),
zone_nm:value[2].toUpperCase().trim(), loc_nm:value[3].toUpperCase().trim(), loc_desc:value[4].replace(/[^\x00-\x7F]/g, "")})
        })
        console.log(importData);
    this.setState({importData:importData,importStep:2})
    }
  }


  importFile = (file) => {
    console.log(file);
    if (file.type!=='text/csv' && file.type!=='application/vnd.ms-excel')
    this.setState({importStep:3})
    else 
    {
      var reader = new FileReader();
      reader.onload = this.readOnLoad(file)
      // Read in the image file as a data URL.
      reader.readAsText(file);
    }
  }

startImport = () => 
{this.setState({importing:true, importStep:1})}

confirmImport = ()=>
{
console.log(this.state.appendImport);
if (this.state.appendImport) {
  axios.get('/api/getLoc?custid='+this.props.custid).then(res=>{
    console.log(res.data);
    console.log(this.state.importData);
    res.data.forEach(value=>{
      this.state.importData.push(
        {
        "dup": false,
        "site_nm": value.site_nm,
        "line_nm": value.line_nm,
        "zone_nm": value.zone_nm,
        "loc_nm": value.loc_nm,
        "loc_desc": value.loc_desc,
        "site_cd": value.site_cd,
        "line_cd": value.line_cd,
        "zone_cd": value.zone_cd,
        "loc_cd": value.loc_cd
    })
    })
    let allsites=_.uniq(_.without(_.pluck(this.state.importData,'site_nm'),'null',''));
    console.log(allsites);
    let alllines=_.uniq(_.without(_.pluck(this.state.importData,'line_nm'),'null',''));
    console.log(alllines);
    let allzones=_.uniq(_.without(_.pluck(this.state.importData,'zone_nm'),'null',''));
    console.log(alllines);
    let alllocs=_.uniq(_.without(_.pluck(this.state.importData,'loc_nm'),'null',''));
    console.log(alllocs);
    var treeData=_.where(this.state.importData,{dup:false});
    // console.log(treeData);
    treeData.forEach((data,index)=>{
        data.site_cd=(data.site_nm?_.indexOf(allsites,data.site_nm)+1:null);
        data.line_cd=data.line_nm?_.indexOf(alllines,data.line_nm)+1:null;
        data.zone_cd=data.zone_nm?_.indexOf(allzones,data.zone_nm)+1:null;
        data.loc_cd=data.loc_nm?_.indexOf(alllocs,data.loc_nm)+1:null;
    })
    // console.log(treeData);
    this.setState({importing:false, importStep:1 , changed:true,treeData:this.convertTreeData(treeData), 
        max_site_cd:_.pluck(treeData,'site_cd').length>0?_.max(_.pluck(treeData,'site_cd')):0,
        max_line_cd:_.pluck(treeData,'line_cd').length>0?_.max(_.pluck(treeData,'line_cd')):0,
        max_zone_cd:_.pluck(treeData,'zone_cd').length>0?_.max(_.pluck(treeData,'zone_cd')):0,
        max_loc_cd:_.pluck(treeData,'loc_cd').length>0?_.max(_.pluck(treeData,'loc_cd')):0});  
  })
}
else {
let allsites=_.uniq(_.without(_.pluck(this.state.importData,'site_nm'),'null',''));
console.log(allsites);
let alllines=_.uniq(_.without(_.pluck(this.state.importData,'line_nm'),'null',''));
console.log(alllines);
let allzones=_.uniq(_.without(_.pluck(this.state.importData,'zone_nm'),'null',''));
console.log(alllines);
let alllocs=_.uniq(_.without(_.pluck(this.state.importData,'loc_nm'),'null',''));
console.log(alllocs);
var treeData=_.where(this.state.importData,{dup:false});
// console.log(treeData);
treeData.forEach((data,index)=>{
    data.site_cd=(data.site_nm?_.indexOf(allsites,data.site_nm)+1:null);
    data.line_cd=data.line_nm?_.indexOf(alllines,data.line_nm)+1:null;
    data.zone_cd=data.zone_nm?_.indexOf(allzones,data.zone_nm)+1:null;
    data.loc_cd=data.loc_nm?_.indexOf(alllocs,data.loc_nm)+1:null;
})
// console.log(treeData);
this.setState({importing:false, importStep:1 , changed:true,treeData:this.convertTreeData(treeData), 
    max_site_cd:_.pluck(treeData,'site_cd').length>0?_.max(_.pluck(treeData,'site_cd')):0,
    max_line_cd:_.pluck(treeData,'line_cd').length>0?_.max(_.pluck(treeData,'line_cd')):0,
    max_zone_cd:_.pluck(treeData,'zone_cd').length>0?_.max(_.pluck(treeData,'zone_cd')):0,
    max_loc_cd:_.pluck(treeData,'loc_cd').length>0?_.max(_.pluck(treeData,'loc_cd')):0});
}
}

  render() {

    var editForm='';
    if (this.state.showedit===true)
    editForm=
    <Modal size="large"
          open={this.state.showedit}
          onClose={this.handleClose}
          closeOnDimmerClick={false}>
          <Modal.Header>Update Location Description</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Header />
              <p>Please add a descrption for the location</p>
        <Form>
        <Form.Field
                  control={TextArea}
                  label="Location Description"
                  autoHeight
                  value={this.state.editDesc}
                  onChange={(e, data) => this.saveDesc(e, data)}
                />
    </Form>
    </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
            <Button icon secondary onClick={this.handleClose}>
              Cancel <Icon name="delete" />
            </Button>
            <Button icon primary onClick={this.updateDesc}>
              Save <Icon name="chevron right" />
            </Button>
          </Modal.Actions>
    </Modal>
      if (this.state.importing===true && this.state.importStep===1)
      editForm=
      <Modal size="large"
            open={this.state.importing}
            onClose={this.handleClose}
            closeOnDimmerClick={false}>
            <Modal.Header>Prepare Import Data</Modal.Header>
            <Modal.Content scrolling>
              <Modal.Description>
       <Header as='h3'> Please prepare your data in Excel with the following 5 headers defined exactly as the example: </Header>
       <Header as='h5'> 
       test_nm : Test Name,<br/> line_nm: Product Line Name,<br/>zone_nm : Zone Name,<br/>loc_nm: Location Name, 
       <br/>loc_desc: Location Description (can be empty) <br/></Header>


       <Header as='h3' > Please save your data in csv format as shown below: </Header>

       <Header as='h3'> After the file is saved, Use the Import button below to import the file </Header>
       <Checkbox checked={this.state.appendImport} label='Import in append mode' onChange={(e, data) => this.setState({appendImport:data.checked})} />
      </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
              <Button icon secondary onClick={this.handleClose}>
                Cancel <Icon name="delete" />
              </Button>
              <FileButton color='blue' onSelect={(file)=>this.importFile(file)}>Import</FileButton>
              {/* <Button icon primary onClick={this.updateDesc}>
                Save <Icon name="chevron right" />
              </Button> */}
            </Modal.Actions>
      </Modal>
          else if (this.state.importing===true && this.state.importStep===3)
           editForm=
           <Modal size="large"
                 open={this.state.importing}
                 onClose={this.handleClose}
                 closeOnDimmerClick={false}>
                 <Modal.Header>Issue with the data file</Modal.Header>
                 <Modal.Content scrolling>
                   <Modal.Description>
            <Header as='h3'> 
            The file header doesn't match the data format expected. <br/> Please correct the data file and reimport
            </Header>
           </Modal.Description>
           </Modal.Content>
           <Modal.Actions>
                   <Button icon secondary onClick={()=>{this.setState({importStep:1})}}>
                     Back <Icon name="angle double left" />
                   </Button>
                   <Button icon primary onClick={this.handleClose}>
                     Close <Icon name="delete" />
                   </Button> 
                 </Modal.Actions>
           </Modal>
          else if (this.state.importing===true && this.state.importStep===2)
          {
            let dupwarning="";  
            if (_.where(this.state.importData,{dup:true}).length>0) 
            dupwarning="There are duplicated locations in the data, they are highlighted below and will not be imported. You can correct the data and reimport."
        let bodyData=this.state.importData.map((data,index)=>{
            return <Table.Row negative={data.dup}>
            <Table.Cell>{data.site_nm}</Table.Cell>
            <Table.Cell>{data.line_nm}</Table.Cell>
            <Table.Cell>{data.zone_nm}</Table.Cell>
            <Table.Cell>{data.loc_nm}</Table.Cell>
            <Table.Cell>{data.loc_desc}</Table.Cell>
            </Table.Row>
        })
          editForm=
          <Modal size="large"
                open={this.state.importing}
                onClose={this.handleClose}
                closeOnDimmerClick={false}>
                <Modal.Header>Confirm Data</Modal.Header>
                <Modal.Content scrolling>
                  <Modal.Description>
           <Header as='h3'> 
           Please confirm the following data set to be imported. <br/>
           {dupwarning}
           </Header>
           <Table basic>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={2}>Test</Table.HeaderCell>
        <Table.HeaderCell  width={2}>Line</Table.HeaderCell>
        <Table.HeaderCell  width={2}>Zone</Table.HeaderCell>
        <Table.HeaderCell width={2} >Location</Table.HeaderCell>
        <Table.HeaderCell width={6} >Location Description</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
        {bodyData}
    </Table.Body>
    </Table>
          </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
                  <Button icon secondary onClick={()=>{this.setState({importStep:1})}}>
                    Back <Icon name="angle double left" />
                  </Button>
                  <Button icon primary onClick={()=>this.confirmImport()}>
                    Confirm <Icon name="check" />
                  </Button> 
                </Modal.Actions>
          </Modal>
          }
    return (
      <Grid style={{ height: '100%' }}>
        <Grid.Column width={16} visible={this.state.visible}>
       <div style={{ height: '100%' }}>
       {/* <FileButton color='blue' onSelect={(file)=>this.startImport(file)}>Import</FileButton> */}
       <Button color='blue' onClick={()=>this.startImport()} >Import</Button>
       <Button color='blue' onClick={()=>this.exportToCSV()} >Export</Button>
       <Button color='blue' onClick={()=>this.addSite()} disabled={!this.state.canAddNewSite}>Create List Manually</Button>
       <Button color='blue' disabled={!this.state.changed} onClick={()=>this.saveTreeData(this.state.treeData)} loading={this.state.savingInProgress}>
       Save</Button>
       <Button color='red' disabled={!this.state.changed} onClick={()=>this.resetTreeData()}>Reset</Button>
       <Segment>
      <Dimmer active={this.state.savingInProgress} inverted>
        <Loader inverted>Saving</Loader>
      </Dimmer>
        <SortableTree
        isVirtualized={false}
          canDrag={true}
          canDrop={(dropData)=>{
            return (dropData.nextParent && dropData.prevParent.type===dropData.nextParent.type);
          }}
          onDragStateChanged={()=>this.setState({changed:true})}
          treeData={this.state.treeData}
        onChange={treeData =>   this.setState({ treeData }) }
        generateNodeProps={(node,path) => {
          var nodeProps={};
          var nodeIcon='';
          if (node.node.type==='site')
          {nodeIcon='building outline';
          nodeProps.buttons=this.state.editing?[]:[
            <Popup trigger={<Button circular icon onClick={()=>this.addNode(node)} >
            <Icon name='plus' />
          </Button>} content='Add Line' />, 
          <Popup trigger={<Button circular icon onClick={()=>this.removeNode(node)}>
            <Icon name='minus'/>
          </Button>} content='Delete Test' />
          ]
        }
          else           if (node.node.type==='line')
          {nodeIcon='sitemap';
          nodeProps.buttons=this.state.editing?[]:[
            <Popup trigger={<Button circular icon onClick={()=>this.addNode(node)} >
            <Icon name='plus' />
          </Button>} content='Add Zone' />, 
          <Popup trigger={<Button circular icon onClick={()=>this.removeNode(node)}>
            <Icon name='minus'/>
          </Button>} content='Delete Line' />
          ]
        }
          else           if (node.node.type==='zone')
          { nodeIcon='map';
          nodeProps.buttons=this.state.editing?[]:[
            <Popup trigger={<Button circular icon onClick={()=>this.addNode(node)} >
            <Icon name='plus' />
          </Button>} content='Add Location' />, 
          <Popup trigger={<Button circular icon onClick={()=>this.removeNode(node)}>
            <Icon name='minus'/>
          </Button>} content='Delete Zone' />
          ];}
           else
           {nodeIcon='map marker alternate' ;
          nodeProps.buttons=this.state.editing?[]:[
            <Label className='descLbl' onClick={()=>this.editNode(node)}>{node.node.desc.substring(0,100)} </Label>,
            <Popup trigger={<Button circular icon onClick={()=>this.removeNode(node)}>
            <Icon name='minus' />
          </Button>} content='Delete Location' />
        //   <Popup trigger={<Button circular icon onClick={()=>this.editNode(node)}>
        //   <Icon name='info' />
        // </Button>} content='Edit Location Description' />
          ]
        }
        if (!node.node.updating) 
        if (!this.state.editing)
        nodeProps.title=(<Label
            onClick={(event)=>this.startUpdateTitle(event,node)}><Icon name={nodeIcon}/> {node.node.title}</Label>);
    else 
    nodeProps.title=(<Label><Icon name={nodeIcon}/>{node.node.title}</Label>);
        else nodeProps.title=(<Input 
            ref={this.setInputRef}
        value={node.node.tempTitle} onChange={(event,data)=>this.updateTitle(event,data,node)}
        onKeyDown={(event,data)=>this.titleKeyDown(event,data,node)}/> );
        return nodeProps
        }}
        />
     </Segment>
      </div>
      <Confirm open={this.state.confirmopen} content={this.state.confirmMsg}
      onCancel={()=>{this.setState({confirmopen:false})}} 
      onConfirm={this.confirmRemoveNode} />
       <Confirm open={this.state.confirmreset} content={this.state.confirmMsg}
      onCancel={()=>{this.setState({confirmreset:false})}} 
      onConfirm={this.confirmResetTree} />
      <Confirm open={this.state.confirmsave} content={this.state.confirmMsg}
      onCancel={()=>{this.setState({confirmsave:false})}} 
      onConfirm={()=>this.confirmSave(this.saveData)} />
      {editForm}
      </Grid.Column>
      <Errorpop ref={this.errorpop} />
      </Grid>
    );
  }
}