import React, { Component } from 'react';
import { Header, Modal, Icon, Button} from 'semantic-ui-react';




export default class Errorpop extends Component {

    state = { modalOpen: false }

    constructor(props) {
        super(props);
        if (!this.props.header)
        this.state.header='Error Accessing Database';
        else this.state.header=this.props.header;
        if (!this.props.message)
        this.state.message='We\'ve encountered an issue accessing the database,</br> Please contact FSR Laboratory at (908)516-2762 for support.';
        else this.state.message=this.props.message;    
    }

    handleOpen = () => this.setState({ modalOpen: true })
  
    handleClose = () => this.setState({ modalOpen: false })
  
    
    render() {
        return (
            <div>
   <Modal         open={this.state.modalOpen}
        onClose={this.handleClose}
        >
    <Modal.Header>System Error</Modal.Header>
    <Modal.Content>
        <Modal.Description>
        <Header>{this.state.header}</Header>
        {this.state.message}
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
          <Button color='green' onClick={this.handleClose}>
            <Icon name='checkmark' /> OK
          </Button>
        </Modal.Actions>
  </Modal>
            </div>
        );
    }
}